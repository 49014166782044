<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="content" v-if="dataReady">
    <h2>My Assignments</h2>

    <div id="content_box" v-for="aAssign in assigns" :key="aAssign.id">
      <div id="inner_text">  
        {{ aAssign.date }}
      </div> 
      <div id="inner_text">  
        {{ aAssign.name }}
      </div>
      <div id="inner_text">  
        Type: {{ aAssign.type }}
      </div> 
      <div id="inner_text">  
        Content(s): {{ aAssign.content }}
      </div> 
      <div id="inner_text" v-if="aAssign.video">  
        Video:
        <router-link
            :to="{
              name: 'watchmyvideo',
              query: {
                url: aAssign.videoURL,
                assignID: aAssign.id
              }
            }"
          >
           {{ aAssign.video }}
      </router-link>
      </div>
      <div id="inner_text" v-if="aAssign.place">  
        Place: {{ aAssign.place }}
      </div> 
      <div id="inner_text" v-if="aAssign.design=='STEM'">  
        <router-link
            :to="{
              name: 'designworksheetstem',
              query: {
                assignID: aAssign.id
              }
            }"
          >
          Use STEM Design Worksheet
      </router-link>
      </div>
      <div id="inner_text" v-if="aAssign.design=='Math'">  
        <router-link
            :to="{
              name: 'designworksheetmath',
              query: {
                assignID: aAssign.id
              }
            }"
          >
          Use Math Design Worksheet
      </router-link>
      </div>  
      <div id="inner_text">  
        {{ aAssign.notes }}
      </div>   
    </div>

  </div>
  <div id="content" v-else>
    Data loading, please wait...
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
import firebase from '@firebase/app';

export default {
  name: 'MyAssignmentsView',
  data() {
    return {
      dataReady: true,
      assigns: []
    }
  },
  components: {
    'top-header': TopHeader
  },
  async mounted() {
    var db = firebase.firestore();
    try {
      var assignRef = await db.collection('assignments');
      var theAssigns = await assignRef
      .where('classCode', '==', this.$store.state.user.code)
        .orderBy('date');

      let snapshot = await theAssigns.get();
      snapshot.forEach(doc => {
        this.assigns.push({
          type: doc.data().type,
          date: doc.data().date,
          name: doc.data().name,
          video: doc.data().videoTitle,
          videoURL: doc.data().videoURL,
          class: doc.data().class,
          content: doc.data().content,
          notes: doc.data().notes,
          place: doc.data().place,
          design: doc.data().design,
          photo: doc.data().photo,
          id: doc.id
        });
      }, this);

      this.dataReady = true;
    } catch (err) {
      console.log(err);
    }
  },
};
</script>

<style scoped>
#spacer {
  height: 60px;
}

#content {
  text-align: left;
  margin: auto;
  padding-top: 20px;
  font-size: 18px;
  width: 50%;
}

#content_box {
  text-align: left;
  float:left;
  margin: auto;
  margin-bottom: 30px;
  font-size: 18px;
  width: 90%;
  border-width:2px;
  border-style:solid;
  border-color:black;
  border-radius: 25px;
  padding: 20px;
  background: beige;
	background-clip: border-box;
}

#inner_text {
  margin-bottom: 10px;
}

.error {
  color: red;
  font-size: 18px;
  margin-bottom: 0px;
}

h2 {
  text-align: center;
  font-size: 22px;
  padding: 0px;
}
</style>
