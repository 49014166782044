<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="content" v-if="dataReady">

      <div class="auto-resizable-iframe">
        <div>
          <iframe frameborder="0" :src=embedCode title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>

      <div class="button_group">
        <n-button @click="handleFavorites" ghost type="primary" color="blue"
          v-if="!evaluating && !editting">{{favoritesButton}}</n-button>
        <n-button @click="handleEvaluate" ghost type="primary" color="blue"
          v-if="!evaluating && !editting">{{evalButton}}</n-button>
      </div>

      <div style="margin-top: 30px;" v-if="!evaluating && !editting">
        <div>
          {{ vidDescript }}
        </div>    
      </div>  
    
      <div class="myform" v-if="submitted">
        Thank you for answering the questions!
      </div>

    <div v-if="error" class="error">{{ error }}</div>
    <div class="myform" v-if="evaluating && !submitted">
    <form @submit.prevent="pressed">
      <div class="ques-body" id="ques1" name="ques1">
        The key question was interesting to me?
      </div>
      <div class="rate">
        <VueSlider v-model="ques1" :adsorb="true" :min="1" :max="5" :height="10"/>
      </div> 
      <div class="ques-body" id="ques1" name="ques1">
        The place in the video was interesting to me?
      </div>
      <div class="rate">
        <VueSlider v-model="ques2" :adsorb="true" :min="1" :max="5" :height="10"/>
      </div>
      <div class="ques-body" id="ques1" name="ques1">
        The response to the question made sense to me?
      </div>
      <div class="rate">
        <VueSlider v-model="ques3" :adsorb="true" :min="1" :max="5" :height="10"/>
      </div>
      <div>
        <textarea v-model="ques4" placeholder="What I noticed about the place..." rows="5" />
      </div>
      <div>
        <textarea v-model="ques5" placeholder="Questions I would like to answer..." rows="5" />
      </div>
      <div>
        <textarea v-model="ques6" placeholder="My strategy for answering the questions.." rows="5" />
      </div>
      <n-button @click="handleSubmit" ghost type="primary" color="blue">Submit</n-button>
    </form>  
    </div>   

    <div class="myform" v-if="editting && !submitted">
    <form @submit.prevent="pressed">
      <div class="ques-body" id="ques1" name="ques1">
        The key question was interesting to me?
      </div>
      <div class="rate">
        <VueSlider v-model="ques1" :adsorb="true" :min="1" :max="5" :height="10"/>
      </div>
      
      <div class="ques-body" id="ques1" name="ques1">
        The place in the video was interesting to me?
      </div>
      <div class="rate">
        <VueSlider v-model="ques2" :adsorb="true" :min="1" :max="5" :height="10"/>
      </div>
      <div class="ques-body" id="ques1" name="ques1">
        The response to the question made sense to me?
      </div>
      <div class="rate">
        <VueSlider v-model="ques3" :adsorb="true" :min="1" :max="5" :height="10"/>
      </div>
      <div>
        <textarea v-model="ques4" placeholder="What I noticed about the place..." rows="5" />
      </div>
      <div>
        <textarea v-model="ques5" placeholder="Questions I would like to answer..." rows="5" />
      </div>
      <div>
        <textarea v-model="ques6" placeholder="My strategy for answering the questions.." rows="5" />
      </div>
      <n-button @click="handleSubmit" ghost type="primary" color="blue">Update</n-button>
    </form>  
    </div>   
  </div>
  
</template>

<script>
import TopHeader from '@/components/Top-Header';
import firebase from '@firebase/app';
import { NButton } from 'naive-ui';
import VueSlider from 'vue-3-slider-component'

export default {
  name: 'WatchMyVideoView',
  components: {
    'top-header': TopHeader,
    'n-button': NButton,
    VueSlider
  },
  data() {
    return {
      error: '',
      dataReady: false,
      favoritesButton: 'Remove From Library',
      evalButton: 'Start Evaluation',
      evaluating: false,
      editting: false,
      embedCode: '',
      vidDescript: '',
      vidTitle: '',
      vidURL: '',
      id: this.$store.state.user.id,
      vidID:'',
      ques1: 1,
      ques2: 1,
      ques3: 1,
      ques4: '',
      ques5: '',
      ques6: '',
      submitted: false,
      takeID: '',
      theAssignment: ''
    };
  },
  methods: {
    async handleEvaluate() {
      if(this.evalButton == 'Start Evaluation') {
        this.evaluating = true; 
        this.evalButton = 'Submit'; 
      }
      else if (this.evalButton == 'Edit Evaluation') {
        this.editting = true; 
        this.evalButton = 'Submit';

        var db = firebase.firestore();
        var myAssignRef = await db.collection('takewalk');
        var myAssign = await myAssignRef
        .where('vidURL', '==', this.$route.query.url)
        .where('id', '==', this.$store.state.user.id)
        .get();  

        myAssign.forEach(doc => {
          this.ques1 = doc.data().question1;
          this.ques2 = doc.data().question2;
          this.ques3 = doc.data().question3;
          this.ques4 = doc.data().question4;
          this.ques5 = doc.data().question5;
          this.ques6 = doc.data().question6; 
          this.takeID = doc.id;      
        });
      }       
    },
    async handleSubmit() {
      var db = firebase.firestore();  
      try {
        if (
          this.ques1.length == 0 ||
          this.ques2.length == 0 ||
          this.ques3.length == 0 ||
          this.ques4.length == 0 ||
          this.ques5.length == 0 ||
          this.ques6.length == 0
        )
          throw 'Please complete all questions.';

          var today = new Date();
          var dd = String(today.getDate()).padStart(2, '0');
          var mm = String(today.getMonth() + 1).padStart(2, '0'); 
          var yyyy = today.getFullYear();
          today = mm + '/' + dd + '/' + yyyy;

          if(this.takeID == '')
            this.takeID = this.makeID(7);

        await db
          .collection('takewalk')
          .doc(this.takeID)
          .set(
            {
              id: this.$store.state.user.id,
              assignID: this.theAssignment,
              vidURL: this.vidURL,
              date: today,
              question1: this.ques1,
              question2: this.ques2,
              question3: this.ques3,
              question4: this.ques4,
              question5: this.ques5,
              question6: this.ques6
            }, 
            {merge: true}
          );

        this.submitted = true;
        this.error = '';
      } catch (err) {
        console.log(err);
          this.error = err;
      }    
    },
    makeID(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567890123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    },
    async handleFavorites() {
      var db = firebase.firestore();
      if(this.favoritesButton == 'Add To My Library')
      {
        var theDocID = this.makeID(6);
        await db
          .collection('favorites')
          .doc(theDocID)
          .set(
            {
              ID: this.id,
              Video_URL: this.vidURL,
              Video_Title: this.vidTitle,
              Description_Long: this.vidDescript
            }
          );
        this.vidID = theDocID;
        this.favoritesButton = 'Remove From Library';
      }      
      else
      {
        await db
          .collection('favorites')
          .doc(this.vidID)
          .delete();
        this.favoritesButton = 'Add To My Library';
      }    
    },
    getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11)
      ? match[2]
      : null;
    }
  },
  async mounted() {
    var db = firebase.firestore();
    try {
      var aVidRef = await db.collection('favorites');
      var theVid = await aVidRef
        .where('Video_URL', '==', this.$route.query.url)
        .where('ID', '==', this.$store.state.user.id)
        .get();  
        
      if(theVid.size == 1) {
        theVid.forEach(doc => {
        this.vidTitle = doc.data().Video_Title;
        this.vidDescript = doc.data().Description_Long;
        this.vidURL = doc.data().Video_URL;
        this.vidID = doc.id;
        this.embedCode = 'https://www.youtube.com/embed/'+this.getId(doc.data().Video_URL);
        });
      }
      else {
        var myVidRef = await db.collection('videos');
        var myVid = await myVidRef
          .where('Video_URL', '==', this.$route.query.url).get();  

        myVid.forEach(doc => {
          this.vidTitle = doc.data().Video_Title;
          this.vidDescript = doc.data().Description_Long;
          this.vidURL = doc.data().Video_URL;
          this.embedCode = 'https://www.youtube.com/embed/'+this.getId(doc.data().Video_URL);
        });

        var theDocID = this.makeID(6);
        await db
          .collection('favorites')
          .doc(theDocID)
          .set(
            {
              ID: this.id,
              Video_URL: this.vidURL,
              Video_Title: this.vidTitle,
              Description_Long: this.vidDescript
            }
          );
        this.vidID = theDocID;
      }   

      var aAssignRef = await db.collection('takewalk');
      var theAssign = await aAssignRef
        .where('vidURL', '==', this.$route.query.url)
        .where('id', '==', this.$store.state.user.id)
        .get();  
      if(theAssign.size >= 1) {
        this.evalButton = 'Edit Evaluation';
      }
      theAssign.forEach(doc => {
        this.theAssignment = doc.data().assignID;
      },this);
      
      this.dataReady = true;   
    } catch (err) {
      console.log(err);
    }

    
  }
};
</script>

<style scoped>
n-button {
  font-size: 18px;
}

.error {
  color: red;
  font-size: 20px;
  margin-bottom: 20px;
}

button {
  width: 190px;
  height: 35px;
  font-size: 18px;
  margin-right: 30px;
  margin-left: 30px;
}

#spacer {
  height: 120px;
}

#content {
  text-align: center;
  margin: auto;
  width: 50%;
  padding: 10px;
  font-size: 20px;
}

.box {
  font-size: 18px;
  float: left;
  margin-top: 20px;
  max-width: 900px;
  text-align:left;
}

.button_group {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.myform {
  width: 100%;
  display: inline-block;
  align-items: center;
  justify-content: center;
}

iframe {
  margin-bottom: 36px;
  float:left;
}

#chp_content {
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  font-size: 18px;
  padding: 10px;
}

#left_list {
  float: left;
  text-align: left;
  padding-right: 40px;
}

#right_list {
  text-align: left;
  float: left;
}

a {
  color: black;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

a:hover,
a.router-link-exact-active {
  font-size: 18px;
  color: blue;
  text-decoration: none;
  text-decoration: underline;
}

h2 {
  text-align: center;
  font-size: 22px;
  padding: 0px;
}

details {
  color: rgb(0, 0, 0);
  font-size: 20px;
}

textarea {
  padding: 10px;
  margin: 10px;
  font-size: 20px;
}

input
{
  width: 660px;
  padding: 10px;
  margin: 10px;
  font-size: 20px;
}

select {
  width: 680px;
  padding: 10px;
  margin: 10px;
  font-size: 20px;
}

textarea {
  height: 100px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #000000;
  border-radius: 4px;
  background-color: #f8f8f8;
  margin: 10px;
  font-size: 20px;
}

.ques-body {
  text-align: center;
  font-size: 20px;
  max-width: 900px;
}

.auto-resizable-iframe {
  max-width: 920px;
  margin: 0px auto;
}

.auto-resizable-iframe > div {
  position: relative;
  padding-bottom: 55%;
  height: 0px;
  border: 16px solid;
  border-image: linear-gradient(45deg,purple,yellow) 10;
}

.auto-resizable-iframe iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.rate {
    display: inline-block;
    height: 55px;
    padding: 0 0px;
    width: 100%;
    max-width: 400px;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color:#ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}

</style>
