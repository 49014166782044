<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="videos_content" v-if="dataReady">
    <div id="searchbox">
      <input type="text" v-model="search" placeholder="Search videos..." /> 
    </div> 
    <div id="searchgroup">
    <div id="dropselect">
      <Multiselect
        style="--ms-placeholder-color: #000;"
        placeholder="Type of Place"
        v-model="type_place"
        mode="tags"
        :close-on-select="false"
        :searchable="true"
        :create-option="true"
        :options="type_place_options"
      />
    </div>
    <div id="dropselect">
      <Multiselect
        style="--ms-placeholder-color: #000;"
        placeholder="Broad Theme"
        v-model="broad_theme"
        mode="tags"
        :close-on-select="false"
        :searchable="true"
        :create-option="true"
        :options="broad_theme_options"
      />
    </div>
    </div>
    <div class="box" v-for="aVideo in filteredVideos" :key="aVideo.id">
      <router-link
            :to="{
              name: 'watchvideo',
              query: {
                id: aVideo.id
              }
            }"
          ><img
            alt="talkSTEM"
            :src='aVideo.imgURL'
          />
          {{ aVideo.title }}
      </router-link>
    </div> 
  </div>
  <div id="videos_content" v-else>
    Data loading, please wait...
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
import firebase from '@firebase/app';
import Multiselect from '@vueform/multiselect'

export default {
  name: 'AllVideosView',
  data() {
    return {
      error: '',
      dataReady: false,
      videos: [],
      search: '',
      type_place: [],
      type_place_options: [
        { value: 'airport', label: 'Airport' },
        { value: 'artwork', label: 'Artwork' },
        { value: 'restaurant', label: 'Cafe/Restaurant' },
        { value: 'campground', label: 'Campground' },
        { value: 'hospital', label: 'Hospital' },
        { value: 'library', label: 'Library' },
        { value: 'museum', label: 'Museum' },
        { value: 'park', label: 'Park' },
        { value: 'performing_arts', label: 'Performing Arts Space' },
        { value: 'playground', label: 'Playground' },
        { value: 'school', label: 'School' },
        { value: 'university', label: 'University' },
        { value: 'shopping', label: 'Shopping Mall' },
        { value: 'sports_arena', label: 'Sports Arena' },
        { value: 'urban_neighborhood', label: 'Urban Neighborhood' },
        { value: 'water_body', label: 'Water Body' },
        { value: 'zoo', label: 'Zoo' }
      ],
      broad_theme: [],
      broad_theme_options: [
        { value: 'air_resistance', label: 'Air Resistance' },
        { value: 'animals', label: 'Animals' },
        { value: 'architecture', label: 'Architecture' },
        { value: 'asking_questions', label: 'Asking Questions' },
        { value: 'community_created', label: 'Community Created' },
        { value: 'dance', label: 'Dance' },
        { value: 'design', label: 'Design' },
        { value: 'earth_motion', label: 'Earth\'s Motion' },
        { value: 'educator_resources', label: 'Educator Resources' },
        { value: 'engineering', label: 'Engineering' },
        { value: 'flight', label: 'Flight' },
        { value: 'food', label: 'Food/Cooking' },
        { value: 'forces', label: 'Forces' },
        { value: 'girl_scouts', label: 'Girl Scouts' },
        { value: 'women_role_models', label: 'Women Role Models' },
        { value: 'music', label: 'Music' },
        { value: 'nature', label: 'Nature' },
        { value: 'navigation', label: 'Navigation' },
        { value: 'panel', label: 'Panel Discussion' },
        { value: 'physics', label: 'Physics' },
        { value: 'science', label: 'Science' },
        { value: 'sound', label: 'Sound' },
        { value: 'space', label: 'Space' }
      ],
    }
  },
  components: {
    'top-header': TopHeader,
    Multiselect
  },
  computed: {
    filteredVideos() {
      var searchVideos = [];
      searchVideos = this.videos.filter(v => {
        return v.descript.toLowerCase().indexOf(this.search.toLowerCase().trim()) != -1;
      });
      
      if(this.type_place.length > 0)
      {
        var airVideos = [];
        if(this.type_place.includes('airport'))
        {
          airVideos = searchVideos.filter(v => {
            return typeof v.airport !== 'undefined';
          });
        }
        var artVideos = [];
        if(this.type_place.includes('artwork'))
        {
          artVideos = searchVideos.filter(v => {
            return typeof v.artwork !== 'undefined';
          });
        }
        var restaurantVideos = [];
        if(this.type_place.includes('restaurant'))
        {
          restaurantVideos = searchVideos.filter(v => {
            return typeof v.restaurant !== 'undefined';
          });
        } 
        var campgroundVideos = [];
        if(this.type_place.includes('campground'))
        {
          campgroundVideos = searchVideos.filter(v => {
            return typeof v.campground !== 'undefined';
          });
        } 
        var hospitalVideos = [];
        if(this.type_place.includes('hospital'))
        {
          hospitalVideos = searchVideos.filter(v => {
            return typeof v.hospital !== 'undefined';
          });
        } 
        var libraryVideos = [];
        if(this.type_place.includes('library'))
        {
          libraryVideos = searchVideos.filter(v => {
            return typeof v.library !== 'undefined';
          });
        }
        var museumVideos = [];
        if(this.type_place.includes('museum'))
        {
          museumVideos = searchVideos.filter(v => {
            return typeof v.museum !== 'undefined';
          });
        } 
        var parkVideos = [];
        if(this.type_place.includes('park'))
        {
          parkVideos = searchVideos.filter(v => {
            return typeof v.park !== 'undefined';
          });
        }
        var paVideos = [];
        if(this.type_place.includes('performing_arts'))
        {
          paVideos = searchVideos.filter(v => {
            return typeof v.performing_arts !== 'undefined';
          });
        } 
        var playgroundVideos = [];
        if(this.type_place.includes('playground'))
        {
          playgroundVideos = searchVideos.filter(v => {
            return typeof v.playground !== 'undefined';
          });
        }
        var schoolVideos = [];
        if(this.type_place.includes('school'))
        {
          schoolVideos = searchVideos.filter(v => {
            return typeof v.school !== 'undefined';
          });
        }
        var universityVideos = [];
        if(this.type_place.includes('university'))
        {
          universityVideos = searchVideos.filter(v => {
            return typeof v.university !== 'undefined';
          });
        }
        var shoppingVideos = [];
        if(this.type_place.includes('shopping'))
        {
          shoppingVideos = searchVideos.filter(v => {
            return typeof v.shopping !== 'undefined';
          });
        }  
        var sportsVideos = [];
        if(this.type_place.includes('sports_arena'))
        {
          sportsVideos = searchVideos.filter(v => {
            return typeof v.sports_arena !== 'undefined';
          });
        } 
        var urbanVideos = [];
        if(this.type_place.includes('urban_neighborhood'))
        {
          urbanVideos = searchVideos.filter(v => {
            return typeof v.urban_neighborhood !== 'undefined';
          });
        }
        var waterVideos = [];
        if(this.type_place.includes('water_body'))
        {
          waterVideos = searchVideos.filter(v => {
            return typeof v.water_body !== 'undefined';
          });
        }
        var zooVideos = [];
        if(this.type_place.includes('zoo'))
        {
          zooVideos = searchVideos.filter(v => {
            return typeof v.zoo !== 'undefined';
          });
        }                                       
        searchVideos = [...new Set([...airVideos, ...artVideos, ...restaurantVideos, 
                        ...campgroundVideos, ...hospitalVideos, ...libraryVideos, ...museumVideos,
                        ...parkVideos, ...paVideos, ...playgroundVideos, ...schoolVideos, ...universityVideos,
                        ...shoppingVideos, ...sportsVideos, ...urbanVideos, ...waterVideos, ...zooVideos])];
      }    

      if(this.broad_theme.length > 0)
      {
        var arVideos = [];
        if(this.broad_theme.includes('air_resistance'))
        {
          arVideos = searchVideos.filter(v => {
            return typeof v.ar !== 'undefined';
          });
        }

        var animalsVideos = [];
        if(this.broad_theme.includes('animals'))
        {
          animalsVideos = searchVideos.filter(v => {
            return typeof v.animals !== 'undefined';
          });
        }
        
        var architectureVideos = [];
        if(this.broad_theme.includes('architecture'))
        {
          architectureVideos = searchVideos.filter(v => {
            return typeof v.architecture !== 'undefined';
          });
        } 
        
        var aqVideos = [];
        if(this.broad_theme.includes('asking_questions'))
        {
          aqVideos = searchVideos.filter(v => {
            return typeof v.aq !== 'undefined';
          });
        } 
        
        var ccVideos = [];
        if(this.broad_theme.includes('community_created'))
        {
          ccVideos = searchVideos.filter(v => {
            return typeof v.cc !== 'undefined';
          });
        } 
        
        var danceVideos = [];
        if(this.broad_theme.includes('dance'))
        {
          danceVideos = searchVideos.filter(v => {
            return typeof v.dance !== 'undefined';
          });
        }

        var designVideos = [];
        if(this.broad_theme.includes('design'))
        {
          designVideos = searchVideos.filter(v => {
            return typeof v.design !== 'undefined';
          });
        }

        var earthMotionVideos = [];
        if(this.broad_theme.includes('earth_motion'))
        {
          earthMotionVideos = searchVideos.filter(v => {
            return typeof v.earth_motion !== 'undefined';
          });
        }

        var eductorResourcesVideos = [];
        if(this.broad_theme.includes('educator_resources'))
        {
          eductorResourcesVideos = searchVideos.filter(v => {
            return typeof v.educator_resources !== 'undefined';
          });
        }

        var engineeringVideos = [];
        if(this.broad_theme.includes('engineering'))
        {
          engineeringVideos = searchVideos.filter(v => {
            return typeof v.engineering !== 'undefined';
          });
        }

        var flightVideos = [];
        if(this.broad_theme.includes('flight'))
        {
          flightVideos = searchVideos.filter(v => {
            return typeof v.flight !== 'undefined';
          });
        }

        var foodVideos = [];
        if(this.broad_theme.includes('food'))
        {
          foodVideos = searchVideos.filter(v => {
            return typeof v.food !== 'undefined';
          });
        }

        var forcesVideos = [];
        if(this.broad_theme.includes('forces'))
        {
          forcesVideos = searchVideos.filter(v => {
            return typeof v.forces !== 'undefined';
          });
        }

        var scoutsVideos = [];
        if(this.broad_theme.includes('girl_scouts'))
        {
          scoutsVideos = searchVideos.filter(v => {
            return typeof v.scouts !== 'undefined';
          });
        }

        var wrmVideos = [];
        if(this.broad_theme.includes('women_role_models'))
        {
          wrmVideos = searchVideos.filter(v => {
            return typeof v.wrm !== 'undefined';
          });
        }

        var musicVideos = [];
        if(this.broad_theme.includes('music'))
        {
          musicVideos = searchVideos.filter(v => {
            return typeof v.music !== 'undefined';
          });
        }

        var natureVideos = [];
        if(this.broad_theme.includes('nature'))
        {
          natureVideos = searchVideos.filter(v => {
            return typeof v.nature !== 'undefined';
          });
        }

        var navigationVideos = [];
        if(this.broad_theme.includes('navigation'))
        {
          navigationVideos = searchVideos.filter(v => {
            return typeof v.navigation !== 'undefined';
          });
        }

        var panelVideos = [];
        if(this.broad_theme.includes('panel'))
        {
          panelVideos = searchVideos.filter(v => {
            return typeof v.panel !== 'undefined';
          });
        }
        
        var physicsVideos = [];
        if(this.broad_theme.includes('physics'))
        {
          physicsVideos = searchVideos.filter(v => {
            return typeof v.physics !== 'undefined';
          });
        }

        var scienceVideos = [];
        if(this.broad_theme.includes('science'))
        {
          scienceVideos = searchVideos.filter(v => {
            return typeof v.science !== 'undefined';
          });
        }

        var soundVideos = [];
        if(this.broad_theme.includes('sound'))
        {
          soundVideos = searchVideos.filter(v => {
            return typeof v.sound !== 'undefined';
          });
        }

        var spaceVideos = [];
        if(this.broad_theme.includes('space'))
        {
          spaceVideos = searchVideos.filter(v => {
            return typeof v.space !== 'undefined';
          });
        }
                                            
        searchVideos = [...new Set([...arVideos, ...animalsVideos, ...architectureVideos , ...aqVideos,
                        ...ccVideos, ...danceVideos, ...designVideos, ...earthMotionVideos, ...eductorResourcesVideos,
                        ...engineeringVideos, ...flightVideos, ...foodVideos, ...forcesVideos, ...scoutsVideos,
                        ...wrmVideos, ...musicVideos, ...natureVideos, ...navigationVideos, ...panelVideos,
                        ...physicsVideos, ...scienceVideos, ...soundVideos, ...spaceVideos
                       ])];
      } 
      console.log(searchVideos.length);
      return searchVideos;    
    }
  },
  methods: {
    getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11)
      ? match[2]
      : null;
    }
  },
  async mounted() {
    var db = firebase.firestore();
    try {
      var imgURL = '';
      var videosRef = await db.collection('videos')
        .orderBy('Playlist');
      let snapshot = await videosRef.get();
      snapshot.forEach(doc => {
        if (doc.data().Video_URL) 
        {
          imgURL = 'https://img.youtube.com/vi/'+this.getId(doc.data().Video_URL)+'/mqdefault.jpg';
          this.videos.push({
            title: doc.data().Video_Title,
            descript: doc.data().Description_Long,
            url: doc.data().Video_URL,
            imgURL: imgURL,
            airport: doc.data().Airport,
            artwork: doc.data().Artwork,
            restaurant: doc.data().Restaurant,
            campground: doc.data().Campground,
            hospital: doc.data().Hospital,
            library: doc.data().Library,
            museum: doc.data().Museum,
            park: doc.data().Park,
            performing_arts: doc.data().Performing_Arts,
            playground: doc.data().Playground,
            school: doc.data().School,
            university: doc.data().University,
            shopping: doc.data().Shopping_Mall,
            sports_arena: doc.data().Sports_Arena,
            urban_neighborhood: doc.data().Urban_Neighborhood,
            water_body: doc.data().Water_Body,
            zoo: doc.data().Zoo,
            ar: doc.data().Air_Resistance,
            animals: doc.data().Animals,
            architecture: doc.data().Architecture,
            aq: doc.data().Asking_Questions,
            cc: doc.data().Community_Created,
            dance: doc.data().Dance,
            design: doc.data().Design,
            earth_motion: doc.data().Earth_Motion,
            educator_resources: doc.data().Educator_Resource,
            engineering: doc.data().Engineering,
            flight: doc.data().Flight,
            food: doc.data().Food,
            forces: doc.data().Forces,
            scouts: doc.data().Girl_Scouts,
            wrm: doc.data().Women_Role_Models,
            music: doc.data().Music,
            nature: doc.data().Nature,
            navigation: doc.data().Navigation,
            panel: doc.data().Panel_Discussion,
            physics: doc.data().Physics,
            science: doc.data().Science,
            sound: doc.data().Sound,
            space: doc.data().Space,
            id: doc.id
        });
        }       
      }, this);
      this.dataReady = true;
    } catch (err) {
      console.log(err);
    }
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
#spacer {
  height: 70px;
}

#dropselect {
  width: 200px;
  margin: auto;
}

#searchgroup {
  display: flex;
  margin: auto;
  margin-top: 22px;
  margin-bottom: 30px;
  width: 95%;
  max-width: 500px;
}

#searchbox {
  margin: auto;
  margin-top: 25px;
  width: 60%;
}

#content {
  text-align: left;
  margin: auto;
  padding-top: 20px;
  font-size: 18px;
  max-width: 950px;
}

#title {
  font-size: 28px;
}

#videos_content {
  text-align: center;
  margin: auto;
  width: 70%;
  padding: 10px;
  font-size: 20px;
}

router-link {
  margin-top: 0px;
}

img {
  margin-bottom: 26px;
  float:left;
}

.box {
  font-size: 18px;
  float: left;
  margin-right: 35px;
  margin-bottom: 110px;
  width: 320px;
  height: 180px;
  border: 12px solid;
  border-image: linear-gradient(45deg,purple,yellow) 10;
}

.error {
  color: red;
  font-size: 18px;
  margin-bottom: 0px;
}

input {
  background-image: url('../../assets/images/searchicon.png');
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding-left: 40px;
  height: 40px;
  width: 100%;
  max-width: 500px;
}

a {
  color: black;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

a:hover,
a.router-link-exact-active {
  font-size: 18px;
  color: blue;
  text-decoration: none;
  text-decoration: underline;
}

</style>
