<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="content" v-if="dataReady"> 
    <h2>STEM Design Worksheet</h2>   
    <div class="myform" v-if="submitted">
        Thank you for answering the questions!
    </div>
    <div v-if="error" class="error">{{ error }}</div>
    <div class="myform" v-if="!submitted">
    <form @submit.prevent="pressed">   
      <div>
        <input type="text" v-model="q1" placeholder="Place (including specific building or subject of your stop as needed)." /> 
      </div> 
      <div>
        <textarea v-model="q2" placeholder="Notice: What do you observe?" rows="5" cols="59" />
      </div>
      <div>
        <textarea v-model="q3" placeholder="Brainstorm: What do you wonder based on what you noticed?" rows="5" cols="59" />
      </div>
      <div>
        <textarea v-model="q4" placeholder="Select: Pick one question and respond to it using the tools you have with you." rows="5" cols="59" />
      </div>
      <div>
        <textarea v-model="q5" placeholder="Think: Respond to your question." rows="5" cols="59" />
      </div>
      <div v-if="assignVid">
        <input type="text" v-model="q6" placeholder="Link to your video" /> 
      </div> 
      <div v-if="assignPhoto" style="text-align: left;margin-left: 40px;margin-top: 10px;">
        <label for="mypic">Upload picture(s): </label>
        <input style="font-size: 18px;"
          v-on="q7"
          id="mypic"
          type="file"
          @change="onFileSelected"
          accept="image/*"
          multiple
        />
      </div>
      <n-button @click="handleSubmit" ghost type="primary" color="blue">Submit</n-button>
    </form>  
    </div>   
  </div>
  
</template>

<script>
import TopHeader from '@/components/Top-Header';
import firebase from '@firebase/app';
import '@firebase/storage';
import { NButton } from 'naive-ui';
export default {
  name: 'DesignWorksheetView',
  components: {
    'top-header': TopHeader,
    'n-button': NButton
  },
  data() {
    return {
      error: '',
      dataReady: false,
      q1: '',
      q2: '',
      q3: '',
      q4: '',
      q5: '',
      q6: '',
      q7: '',
      submitted: false,
      assignID: '',
      myMakeID: '',
      assignPhoto: true,
      assignVid: true,
      selectedFile: null
    };
  },
  methods: {
    async handleSubmit() {
      var db = firebase.firestore();  
      try {
        if (
          this.q1.length == 0 ||
          this.q2.length == 0 ||
          this.q3.length == 0 ||
          this.q4.length == 0 ||
          this.q5.length == 0
        )
          throw 'Please complete all questions.';

          var today = new Date();
          var dd = String(today.getDate()).padStart(2, '0');
          var mm = String(today.getMonth() + 1).padStart(2, '0'); 
          var yyyy = today.getFullYear();
          today = mm + '/' + dd + '/' + yyyy;

          if(this.myMakeID == '')
            this.myMakeID = this.makeID(7);

        var downloadLink = '';
        if(this.selectedFile != null)
        {
          var storage = firebase.storage();
          const storageRef = storage.ref();
          const fileRef = storageRef.child(this.selectedFile.name);
          await fileRef.put(this.selectedFile);
          downloadLink = await fileRef.getDownloadURL();
        }
        else
          downloadLink = this.q7;

        await db
          .collection('makewalk')
          .doc(this.myMakeID)
          .set(
            {
              id: this.$store.state.user.id,
              assignID: this.assignID,
              date: today,
              question1: this.q1,
              question2: this.q2,
              question3: this.q3,
              question4: this.q4,
              question5: this.q5,
              question6: this.q6,
              question7: downloadLink
            }, 
            {merge: true}
          );

        this.submitted = true;
        this.error = '';
      } catch (err) {
        console.log(err);
          this.error = err;
      }    
    },
    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
    },
    makeID(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567890123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    }
  },
  async mounted() {
    var db = firebase.firestore();
    if(this.$route.query.assignID)
    {
      this.assignID = this.$route.query.assignID;    
      try {
        var assignRef = await db.collection('makewalk');
        var theAssign = await assignRef
          .where('assignID', '==', this.$route.query.assignID)
          .get(); 

        theAssign.forEach(doc => {
          this.q1 = doc.data().question1;
          this.q2 = doc.data().question2;
          this.q3 = doc.data().question3;
          this.q4 = doc.data().question4;
          this.q5 = doc.data().question5;
          this.q6 = doc.data().question6;
          this.q7 = doc.data().question7;
          this.myMakeID = doc.id;
        }); 
 
        var aRef = await db.collection('assignments').doc(this.$route.query.assignID);
        let anAssign = await aRef.get();
        this.assignPhoto = anAssign.data().photo;
        this.assignVid = anAssign.data().vid;

      } catch (err) {
        console.log(err);
        this.error = err;
      }
    }
    else if(this.$route.query.docID)
    {
      try {
        var makeRef = await db.collection('makewalk').doc(this.$route.query.docID);
        let theMake = await makeRef.get();
        this.q1 = theMake.data().question1;
        this.q2 = theMake.data().question2;
        this.q3 = theMake.data().question3;
        this.q4 = theMake.data().question4;
        this.q5 = theMake.data().question5;
        this.q6 = theMake.data().question6;
        this.q7 = theMake.data().question7;

        if(theMake.data().assignID)
        {
          this.assignID = theMake.data().assignID;
          this.myMakeID = theMake.id;
          var myRef = await db.collection('assignments').doc(this.assignID);
          let anAssign = await myRef.get();
          this.assignPhoto = anAssign.data().photo;
          this.assignVid = anAssign.data().vid;
        }        

      } catch (err) {
        console.log(err);
        this.error = err;
      }
    }
    this.dataReady = true;     
  }
};
</script>

<style scoped>
label {
  text-align: left;
}
.error {
  color: red;
  font-size: 20px;
  margin-bottom: 20px;
}

button {
  margin-top: 20px;
  font-size: 20px;
  width: 140px;
  height: 35px;
  font-size: 18px;
}

#spacer {
  height: 90px;
}

#content {
  text-align: center;
  margin: auto;
  padding: 10px;
  font-size: 20px;
}

.box {
  font-size: 18px;
  float: left;
  margin-right: 30px;
  margin-bottom: 40px;
  width: 900px;
  height: 506px;
  border: 16px solid;
  border-image: linear-gradient(45deg,purple,yellow) 10;
  text-align:left;
}

.button_group {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.myform {
  display: inline-block;
}

a {
  color: black;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

a:hover,
a.router-link-exact-active {
  font-size: 18px;
  color: blue;
  text-decoration: none;
  text-decoration: underline;
}

h2 {
  text-align: center;
  font-size: 22px;
  padding: 0px;
}

details {
  color: rgb(0, 0, 0);
  font-size: 20px;
}

input[type='text']
{
  width: 88%;
  padding: 10px;
  margin: 10px;
  font-size: 20px;
  border: 1px solid #000000;
  border-radius: 4px;
  background-color: #f8f8f8;
}

textarea {
  width: 90%;
  height: 100px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #000000;
  border-radius: 4px;
  background-color: #f8f8f8;
  margin: 10px;
  font-size: 20px;
}
</style>
