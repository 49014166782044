<template>
  <div id="spacer"></div>
  <img alt="talkSTEM Logo" src="../assets/images/logots.png" />
  <div id="spacer"></div>
  <div v-if="myStuds.length==0" id="c
  ontent">
    <div id="mytitle">
      Enter your access code
    </div>
    <div v-if="error" class="error">{{ error }}</div>
    <form v-if="myStuds.length==0" @submit.prevent="pressed">
      <div class="text">
        <input type="text" v-model="code" placeholder="Access Code" />
      </div>
      <div style="padding-top: 10px;">
        <button type="submit">Submit</button>
      </div>
    </form>
  </div>
  <div v-else>
    <a id="mylink" @click="changeClass">Change Class</a>
  </div>
  <div v-if="myStuds.length>0" id="contentbuttons"> 
    <p style="font-size: 28px;">Welcome to {{ className }}</p>
    <div id="buttondiv">
      <div v-if="error1" class="error">{{ error1 }}</div>
      <p>Choose your name</p>
      <button v-for="stud in myStuds" :key="stud.ID" id="buttonstud1" :class="{ active: stud.isActive }" @click="toggleShow(stud)">{{ stud.name }}</button>  
    </div> 
    <form @submit.prevent="clicked">
      <Transition name="slide-fade">
      <div v-if="showLogin" id="logdiv">
        <p>Now enter your secret words</p>
        <input id="pwinput" type="text" v-model="pw" />
        <button id="buttonsubmit" type="submit" :disabled="isDisabled">Sign in</button>
      </div>
      </Transition>
    </form>   
  </div>
</template>

<script>
import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
export default {
  name: 'RegisterView',
  methods: {
    changeClass() {
      localStorage.removeItem('ct_access');
      this.myStuds = [];
      this.code = '';
      this.error = '';
    },
    async clicked() {
      try {
        var thePass = this.pw.trim().toLowerCase().replace(/\s+/g, '');
        var db = firebase.firestore();
        await firebase
          .auth()
          .signInWithEmailAndPassword(this.user, thePass);
        var user = firebase.auth().currentUser;

        let tl = new Date().getTime();
        var docRef = await db.collection('users').doc(user.uid);
        var theDoc = await docRef.get();
        docRef.update({
          logintime: tl
        })
        var aUser = {
          id: user.uid,
          name: theDoc.data().name,
          email: theDoc.data().email,
          code: theDoc.data().code  
        };
        await this.$store.commit('STORE_USER', aUser);
        this.$router.replace({ name: 'start' });
      } catch (err) {
        console.log(err);
        if (
          err.code == 'auth/wrong-password' ||
          err.code == 'auth/user-not-found' ||
          err.code == 'auth/internal-error'
        )
          this.error1 = 'Invalid Login';
        else this.error = err;
      }
    },
    async pressed() {
      try {
        if (this.code.length == 0)
          throw 'Please enter the code.';
        
        localStorage.setItem('ct_access', this.code);
        var db = firebase.firestore();
        var myStuds1 = [];
        var aName = '';
        var classesRef = await db.collection('classes');

        var theStuds = await classesRef
          .where('code', '==', this.code.trim());
        await theStuds.get().then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {  
            myStuds1 = doc.data().roster;
            aName = doc.data().name;
          });        
        });
        this.myStuds = myStuds1;
        this.className = aName;

        if(this.myStuds != null)
        {
          this.myStuds.sort(function(a, b){
            var nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase();
          if (nameA < nameB) //sort string ascending
            return -1;
          if (nameA > nameB)
            return 1;
          return 0; //default return value (no sorting)
        });
      }

      if(this.myStuds.length == 0) 
        throw 'Invalid Code';      
      } catch (err) {
        if (err.code == 'auth/user-not-found')
          this.error = 'The email does not have a user associated with it.';
        else this.error = err;
        console.log(err);
      }      
    },
    toggleShow(student) {
      this.myStuds.forEach((stud) => stud.isActive = false);
      this.showLogin = true;
      this.user = student.user;
      student.isActive = true;
    }
  },
  async mounted() {
    if(localStorage.getItem('ct_access')) {
      this.code = localStorage.getItem('ct_access');  
      try {
      var db = firebase.firestore();
        var myStuds1 = [];
        var aName = '';
        var classesRef = await db.collection('classes');

        var theStuds = await classesRef
          .where('code', '==', this.code.trim());
        await theStuds.get().then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {  
            myStuds1 = doc.data().roster;
            aName = doc.data().name;
          });        
        });
        this.myStuds = myStuds1;
        this.className = aName;

        if(this.myStuds != null)
        {
          this.myStuds.sort(function(a, b) {
            var nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase();
          if (nameA < nameB) //sort string ascending
            return -1;
          if (nameA > nameB)
            return 1;
          return 0; //default return value (no sorting)
          });
        }
      } catch (err) {
          this.error = err;
          console.log(err);
      }
    }
      
  },
  data() {
    return {
      code: '',
      error: '',
      error1: '',
      myStuds: [],
      className: '',
      showLogin: false,
      user: '',
      pw: '',
      isActive: false
    };
  },
  computed: {
    isDisabled: function () {
      return this.pw==''
    }
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(40px);
  opacity: 0;
}

.active {
  background-color: #fcd216; 
}
   
#spacer {
  height: 30px;
}

.error {
  color: red;
  font-size: 18px;
}

p {
  text-align: left;
  font-size: 20px;
  margin-bottom: 5px;
}

#mylink {
  font-size: 18px;
  color: blue;
  text-decoration: underline;
}

input {
  width: 200px;
  padding: 10px;
  margin: 10px;
  font-size: 18px;
}

#pwinput {
  width: 240px;
  padding: 4px;
  margin: 4px;
  font-size: 16px;
  float: left;
}

select {
  width: 435px;
  padding: 15px;
  margin: 10px;
  font-size: 20px;
}

#buttonstud1 {
  font-size: 16px;
  width: auto;
  padding: 10px 12px;
  margin: 10px;
  border-radius:10px;
  float: left;
}

#buttonstud1:hover {
    background-color: #f6e494;
}

#buttonstud1:focus {
    background-color: #fcd216;
}

#buttonsubmit {
  font-size: 16px;
  background-color: #fbaa07;
  color: white;
  width: auto;
  padding: 8px;
  margin-top: 12px;
  border-radius:8px;
  float: left;
  height: 38px;
}

button {
  width: 110px;
  height: 44px;
  font-size: 20px;
}

#content {
  text-align: center;
  margin-top: 20px;
}

#contentbuttons {
  max-width: 900px;
  margin: 0 auto;
}

#logdiv {
  margin-top: 2px;
  width: 300px;
  float: left;
}

#buttondiv {
  margin-top: 20px;
  max-width: 900px;
  float: left;
}

#mytitle {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 12px;
}

#mysubtitle {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.link {
  color: rgb(0, 0, 0);
  list-style: none;
  text-decoration: none;
}

.link {
  font-size: 18px;
  transition: 0.3s ease all;
  padding-bottom: 1px;
  border-bottom: 1px solid transparent;
}

.link:hover {
  color: blue;
  border-color: #00afea;
}

a.router-link-exact-active {
  color: blue;
  border-color: #00afea;
}
</style>
