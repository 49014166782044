import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import firebase from 'firebase/app';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC1AMXj6irqlCwF5IzrqIW0s7VwFYPturY",
  authDomain: "talkstem-5e4b3.firebaseapp.com",
  databaseURL: 'https://talkstem-5e4b3.firebaseio.com',
  projectId: "talkstem-5e4b3",
  storageBucket: "talkstem-5e4b3.appspot.com",
  messagingSenderId: "451652094722",
  appId: "1:451652094722:web:95850dcb01c2ae143ad01b",
  measurementId: "G-7L4HDCPYSW"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

let app;
if (!app) {
  app = createApp(App)
    .use(store)
    .use(router)
    .mount('#app');
}
