<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="content" v-if="dataReady">

    <router-link class="link" :to="{ name: 'allvideos' }">
    <div id="content_box">
      <img
            alt="Videos"
            src="../../assets/images/video_header.png"
            style="float:left"
          />
      <div id="inner_text">  
        Find all the talkSTEM videos here.
      </div>
    </div>
    </router-link>

    <router-link class="link" :to="{ name: 'assignments' }">
    <div id="content_box">
      <img
            alt="Assignments"
            src="../../assets/images/assign_header.png"
            style="float:left"
          />
      <div id="inner_text">  
        Your teacher will post your assignments here.
      </div>
    </div>
    </router-link>

    <router-link class="link" :to="{ name: 'myvideos' }">
    <div id="content_box">
      <img
            alt="Library"
            src="../../assets/images/library_header.png"
            style="float:left"
          />
      <div id="inner_text">  
        Your custom video library
      </div>
    </div>
    </router-link>

    <router-link class="link" :to="{ name: 'mystops' }">
    <div id="content_box">
      <img
            alt="Library"
            src="../../assets/images/stops_header.png"
            style="float:left"
          />
      <div id="inner_text">  
        Create your own STEM stops and keep a field journal.
      </div>
    </div>
    </router-link>

  </div>
  
</template>

<script>
import TopHeader from '@/components/Top-Header';

export default {
  name: 'StartView',
  data() {
    return {
      dataReady: true
    }
  },
  components: {
    'top-header': TopHeader
  },
};
</script>

<style scoped>
#spacer {
  height: 100px;
}

#content {
  margin: auto;
  padding-top: 20px;
  font-size: 18px;
  max-width: 760px;
}

#content_box {
  text-align: left;
  float:left;
  margin-left: 50px;
  margin-bottom: 50px;
  font-size: 18px;
  width: 260px;
  height: 220px;
  border-width:2px;
  border-style:solid;
  border-color:black;
  border-radius: 25px;
  padding: 15px;
  background-image: linear-gradient(to bottom, rgb(250, 255, 187) 0%, rgba(250, 255, 187, 1) 100%), linear-gradient(to bottom, rgb(44, 94, 211, 1) 0%, rgba(44, 94, 211, 1) 100%);
  background-clip: content-box, padding-box;
}

#inner_text {
  margin-left: 20px;
  margin-top: 80px;
  margin-right: 20px;
  font-size: 20px;
}

#title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: auto;
  padding: 5px;
}

.error {
  color: red;
  font-size: 18px;
  margin-bottom: 0px;
}
</style>
