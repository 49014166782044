<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="content">
    <h2>Change Password</h2>
    <div v-if="error" class="error">{{ error }}</div>
    <div v-if="message" class="message">{{ message }}</div>
    <form @submit.prevent="pressed">
      <div v-if="message == ''" class="text">
        <input
          type="password"
          v-model="old_pass"
          placeholder="Current Password"
        />
      </div>
      <div v-if="message == ''" class="text">
        <input type="password" v-model="new_pass" placeholder="New Password" />
      </div>
      <div v-if="message == ''" class="text">
        <input
          type="password"
          v-model="confirm_pass"
          placeholder="Confirm Password"
        />
      </div>
      <button v-if="message == ''" type="submit">Submit</button>
    </form>
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
import firebase from '@firebase/app';

export default {
  name: 'ChangePass',
  components: {
    'top-header': TopHeader
  },
  methods: {
    async pressed() {
      var newPassword = this.new_pass;
      const confirmPassword = this.confirm_pass;

      try {
        if (newPassword.length == 0) throw 'Please enter a new password';
        if (newPassword != confirmPassword) throw 'Passwords must match';
        if (newPassword.length < 6)
          throw 'Passwords must be at least 6 charcaters';

        const user = firebase.auth().currentUser;
        var myOldPass = this.old_pass.replace(/\s+/g, '');
        newPassword = newPassword.replace(/\s+/g, '');
        const credential = firebase.auth.EmailAuthProvider.credential(
          user.email,
          myOldPass
        );
        await user.reauthenticateWithCredential(credential);
        await user.updatePassword(newPassword);
        this.message = 'Password Updated!';
        this.error = '';

        var db = firebase.firestore();
        var userID = this.$store.state.user.id;
          await db
            .collection('users')
            .doc(userID)
            .set(
              {
                password: this.new_pass
              },
              { merge: true }
            );

      } catch (err) {
        console.log(err);
        if (err.code == 'auth/wrong-password' || err.code == 'auth/internal-error')
          this.error = 'Incorrect current password';
        else this.error = err;
      }
    }
  },
  data() {
    return {
      error: '',
      message: '',
      old_pass: '',
      new_pass: '',
      confirm_pass: ''
    };
  }
};
</script>

<style scoped>
#spacer {
  height: 120px;
}

.error {
  color: red;
  font-size: 18px;
}

.message {
  color: black;
  font-size: 18px;
}

select,
input {
  width: 400px;
  padding: 10px;
  margin: 10px;
  font-size: 20px;
}

button {
  width: 120px;
  height: 50px;
  font-size: 18px;
  margin-top: 16px;
}
#content {
  text-align: center;
  margin: auto;
  padding: 10px;
}
</style>
