<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="content" v-if="dataReady">
       
      <div class="auto-resizable-iframe">
        <div>
          <iframe frameborder="0" :src=embedCode title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>

      <div class="button_group">
      <n-button @click="handleFavorites" ghost type="primary" color="blue"
        >{{favoritesButton}}</n-button>
      </div>

      <div class="box">
        <div>
          {{ vidDescript }}
        </div>    
      </div>  
      
  </div> 
</template>

<script>
import TopHeader from '@/components/Top-Header';
import firebase from '@firebase/app';
import { NButton } from 'naive-ui';
export default {
  name: 'WatchVideoView',
  components: {
    'top-header': TopHeader,
    'n-button': NButton
  },
  methods: {
    makeID(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    },
    async handleFavorites() {
      var db = firebase.firestore();
      if(this.favoritesButton == 'Add To My Library')
      {
        var theDocID = this.makeID(6);
        await db
          .collection('favorites')
          .doc(theDocID)
          .set(
            {
              ID: this.id,
              Video_URL: this.vidURL,
              Video_Title: this.vidTitle,
              Description_Long: this.vidDescript
            }
          );
        this.favVidID = theDocID;
        this.favoritesButton = 'Remove From Library';
      }      
      else
      {
        await db
          .collection('favorites')
          .doc(this.favVidID)
          .delete();
        this.favoritesButton = 'Add To My Library';
      }   
    },
    getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11)
      ? match[2]
      : null;
    }
  },
  data() {
    return {
      error: '',
      dataReady: false,
      favoritesButton: 'Add To My Library',
      embedCode: '',
      vidDescript: '',
      vidTitle: '',
      vidURL: '',
      favVidID: '',
      id: this.$store.state.user.id
    };
  },
  async mounted() {
    var db = firebase.firestore();
    try {
      var videoRef = await db.collection('videos').doc(this.$route.query.id);
      let theVid = await videoRef.get();
      this.vidTitle = theVid.data().Video_Title;
      this.vidDescript = theVid.data().Description_Long;
      this.vidURL = theVid.data().Video_URL;
      this.embedCode = 'https://www.youtube.com/embed/'+this.getId(theVid.data().Video_URL);

      var favRef = await db.collection('favorites');
      var theFav = await favRef
            .where('ID', '==', this.$store.state.user.id)
            .where('Video_URL', '==', this.vidURL);
      let snapshot = await theFav.get();
      snapshot.forEach(doc => {
        if(doc.exists) {
          this.favoritesButton = 'Remove From Library';
          this.favVidID = doc.id;
        }
      }, this);
      
      this.dataReady = true;   
    } catch (err) {
      console.log(err);
    }
  }
};
</script>

<style scoped>
n-button {
  font-size: 18px;
}
button {
  width: 190px;
  height: 35px;
  font-size: 18px;
  margin-right: 30px;
  margin-left: 30px;
}

#spacer {
  height: 120px;
}

#content {
  text-align: center;
  margin: auto;
  width: 50%;
  padding: 10px;
  font-size: 20px;
}

.box {
  font-size: 18px;
  float: left;
  margin-top: 20px;
  max-width: 900px;
  text-align:left;
}

.button_group {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

iframe {
  margin-bottom: 36px;
  float:left;
}

#chp_content {
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  font-size: 18px;
  padding: 10px;
}

#left_list {
  float: left;
  text-align: left;
  padding-right: 40px;
}

#right_list {
  text-align: left;
  float: left;
}

a {
  color: black;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

a:hover,
a.router-link-exact-active {
  font-size: 18px;
  color: blue;
  text-decoration: none;
  text-decoration: underline;
}

h2 {
  text-align: center;
  font-size: 22px;
  padding: 0px;
}

details {
  color: rgb(0, 0, 0);
  font-size: 20px;
}

.auto-resizable-iframe {
  max-width: 920px;
  margin: 0px auto;
}

.auto-resizable-iframe > div {
  position: relative;
  padding-bottom: 55%;
  height: 0px;
  border: 16px solid;
  border-image: linear-gradient(45deg,purple,yellow) 10;
}

.auto-resizable-iframe iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
</style>
