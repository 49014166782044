<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="videos_content" v-if="dataReady">
    <h2>My Library</h2>
    <div class="box" v-for="aVideo in videos" :key="aVideo.id">
      <router-link
            :to="{
              name: 'watchmyvideo',
              query: {
                url: aVideo.url
              }
            }"
          ><img
            alt="talkSTEM"
            :src='aVideo.imgURL'
          />
          {{ aVideo.title }}
      </router-link>
    </div> 
  </div>
  <div id="videos_content" v-else>
    Data loading, please wait...
  </div>
</template>

<script>
import TopHeader from '@/components/Top-Header';
import firebase from '@firebase/app';
export default {
  name: 'VideosView',
  components: {
    'top-header': TopHeader
  },
  data() {
    return {
      error: '',
      dataReady: false,
      videos: []
    };
  },
  methods: {
    getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11)
      ? match[2]
      : null;
    }
  },
  async mounted() {
    var db = firebase.firestore();
    try {
      var imgURL = '';
      var videosRef = await db.collection('favorites');
      var theVideos = await videosRef
            .where('ID', '==', this.$store.state.user.id);
      let snapshot = await theVideos.get();
      snapshot.forEach(doc => {
        if (doc.data().Video_URL) 
        {
          imgURL = 'https://img.youtube.com/vi/'+this.getId(doc.data().Video_URL)+'/mqdefault.jpg';
          this.videos.push({
            title: doc.data().Video_Title,
            url: doc.data().Video_URL,
            imgURL: imgURL,
            id: doc.id
        });
        }       
      }, this);
      this.dataReady = true;
    } catch (err) {
      console.log(err);
    }
  }
};
</script>
<style scoped>
#spacer {
  height: 70px;
}

#title {
  font-size: 24px;
}

#videos_content {
  text-align: center;
  margin: auto;
  width: 70%;
  padding: 10px;
  font-size: 20px;
}

router-link {
  margin-top: 0px;
}

img {
  margin-bottom: 26px;
  float:left;
}

.box {
  font-size: 18px;
  float: left;
  margin-right: 35px;
  margin-bottom: 120px;
  width: 320px;
  height: 180px;
  border: 12px solid;
  border-image: linear-gradient(45deg,purple,yellow) 10;
}

#left_list {
  float: left;
  text-align: left;
  padding-right: 40px;
}

#right_list {
  text-align: left;
  float: left;
}

a {
  color: black;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

a:hover,
a.router-link-exact-active {
  font-size: 18px;
  color: blue;
  text-decoration: none;
  text-decoration: underline;
}

h2 {
  text-align: center;
  font-size: 22px;
  padding: 0px;
}
</style>

